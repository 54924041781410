import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import deleteIcon from "../../../images/delete-red.svg";
import editIcon from "../../../images/edit-icon.svg";
import InputField from "../../custom/inputfield/InputField";
import Dropdown from "../../custom/dropdown/Dropdown";
import Button from "../../custom/button/Button";
import { Validators } from "../../../utilities/Validator";
import useAxios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import GoogleAddress from "../../custom/google-api-address/GoogleAddress";
import SelectedPlans from "./SelectedPlans";
import Message from "../../custom/toster/Message";
import OrgUserList from "../../organizations/org-users/OrgUserList";
import icon_info from '../../../images/info-icon.svg';
import Checkbox from "../../custom/checkbox/Checkbox";
import MyDocument from "../../My-Document/MyDocument";
import ProofDocument from "../../My-Document/ProofDocument";
import UploadTemplate from "../../organizations/create-organization/UploadTemplate";
import { s3UrlLink } from "../../../utilities/S3Url";
import fileUploadStyle from "../../My-Document/FileUpload.module.css";
import FileUploadIcon from "../../../images/fileupload.svg";
import DeleteIcon from "../../../images/deleteLogo.svg";
import useAxiosUpload from "../../../axiosinstanceUpload";

function EditOrganization() {
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const axiosInstance = useAxios();
  const [orgName, setOrgName] = useState("");
  const [streetAdd, setStreetAdd] = useState("");
  const [editMode, setEditMode] = useState(null);
  const [city, setCity] = useState("");
  const [totalHcp, setTotalHcp] = useState("");
  const [totalPatients, setTotalPatients] = useState("");
  const [totalAdmins, setTotalAdmins] = useState("")
  const [stateValue, setStateValue] = useState("");
  const [postCode, setPostCode] = useState("");
  const [postCodeErr, setPostCodeErr] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [featureErrorMessage, setFeatureErrorMessage] = useState("");
  const [addressError, setaddressError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [planList, setPlanList] = useState("");
  const [platFormData, setPlatFormData] = useState([]);
  const [platFormList, setPlatFormList] = useState([]);
  const [editPlatformList, setEditplatformList] = useState([]);
  const [assignedPlanList, setAssignedPlanList] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [planDataRefresh, setPlanDataRefresh] = useState(false);
  const [countrydata, setCountryLists] = useState([]);
  const [country, setcountry] = useState("");
  const [paltformErr, setPaltformErr] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [selectedOption, setSelectedOption] = useState([]);
  const [autoJoinPatient, setAutoJoinPatient] = useState(false)
  const [logoUrl, setLogoUrl] = useState("");

  const orgNameRef = useRef();
  const streetAddRef = useRef();
  const cityRef = useRef();
  const stateValueRef = useRef();
  const postCodeRef = useRef();
  const phoneRef = useRef();
  const navigate = useNavigate();
  const { akou } = useParams();
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const axiosInstanceUpload = useAxiosUpload();

  const toggleVisibility = (e) => {
    e.preventDefault();
    setIsHidden(!isHidden);
  };

  const changeOrgName = (event) => {
    setOrgName(event);
  };
  const changeStreetAdd = (event) => {
    setStreetAdd(event);
    setaddressError(false);
  };
  const changeCity = (event) => {
    setCity(event);
  };
  const changeStateValue = (event) => {
    setStateValue(event);
  };
  const changePostCode = (event, err) => {
    setPostCode(event);
    setPostCodeErr(err);
  };
  const changePhone = (event, err) => {
    setPhone(event);
    setPhoneErr(err);
  };
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedFeatureItems, setCheckedFeatureItems] = useState({});

  const planOptions = planData.map((item) => {
    return { value: item.p_id, label: item.p_nm };
  });
  const platFormOptions = platFormData.map((item) => {
    return { value: item.plat_id, label: item.name };
  });
  const selectedPlatforms = platFormList.map(item => item.value);

  const json = JSON.stringify({
    org_name: orgName,
    address: addressList,
    approved_status: 1,
    features: [checkedFeatureItems],
    specility: [checkedItems],
    plan_id: [planList],
    platforms: selectedPlatforms,
  });

  const handleSave = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extorg/org/update_organization/${akou}?ak_id=${login.ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        setMessageType("success");
        setMessage("Organization Updated");
        setTimeout(() => {
          navigate("/organization-list");
          setMessage(false);
        }, 2500);
      }
    } catch (err) {
      setMessage(err.message);
      setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const numberOfCheckedItems = Object.values(checkedItems).filter(Boolean)
      .length;
    const numberOfCheckedFeatureItems = Object.values(
      checkedFeatureItems
    ).filter(Boolean).length;
    if (numberOfCheckedItems === 0) {
      setErrorMessage("Please select at least one option");
    } else {
      setErrorMessage("");
    }
    if (numberOfCheckedFeatureItems === 0) {
      setFeatureErrorMessage("Please select at least one option");
    } else {
      setFeatureErrorMessage("");
    }
    if (!orgName) {
      orgNameRef.current.errUpdate();
    }
    if (platFormList.length === 0) {
      setPaltformErr(true)
    }
    if (addressList.length === 0) {
      setLocationError(true);
    }
    if (
      !orgName ||
      platFormList.length === 0 ||
      addressList.length === 0 ||
      numberOfCheckedItems === 0 ||
      numberOfCheckedFeatureItems === 0
    ) {
    } else {
      handleSave();
    }
  };



  const featuresData = [
    {
      label: "Akunah Med",
      value: "Akunah Med",
      locked: true,
    },
    {
      label: "Reflect",
      value: "Reflect",
      locked: true,
    },
    {
      label: "Pacs",
      value: "Pacs",
      locked: true,
    },
    {
      label: "Radiology",
      value: "Radiology",
      locked: true,
    },
    {
      label: "Pathology",
      value: "Pathology",
      locked: true,
    },
    {
      label: "Insight",
      value: "Insight",
      locked: false,
    },
    {
      label: "Interorg sharing",
      value: "Interorg_sharing",
      locked: false,
    },
    {
      label: "Report",
      value: "Report",
      locked: false,
    },
  ];

  const customUserStrings = {
    selectSomeItems: 'Platforms...',
    allItemsAreSelected: `${platFormList.length} Platform selected`,
  };

  const [form, setItems2] = useState([]);
  const [featureForm, setFeatureForm] = useState(featuresData);

  let a = form.filter((items) => items.name);
  let b = featureForm.filter((items) => items.value);

  const options = a.map((item) => {
    return {
      id: item.value,
      label: item.label,
      value: item.name,
      locked: true,
      active: item.active,
    };
  });

  const featureOptions = b.map((item) => {
    return {
      id: item.value,
      label: item.label,
      value: item.value,
      locked: item.locked,
      active: item.active,
    };
  });


  const handleChange1 = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.value]: e.target.checked,
    });
  };
  const handleChange2 = (e) => {
    setCheckedFeatureItems({
      ...checkedFeatureItems,
      [e.target.value]: e.target.checked,
    });
  };

  const handlePlanListData = (e) => {
    setPlanList(e);
  };
  const resetLocation = () => {
    setStreetAdd("");
  };
  const handlePlatFormListData = (e) => {
    setPlatFormList(e);
  };

  useEffect(() => {
    const getSpecialityData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/get_specilaity?ak_id=${login.ak_id}`
        );
        setItems2(response.data.data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getSpecialityData();
  }, []);

  useEffect(() => {
    if (Array.isArray(editPlatformList)) {
      const filteredPlatList = platFormData
        .filter(item => editPlatformList.includes(item.plat_id)) // Filter based on matching IDs
        .map(item => {
          return {
            value: item.plat_id,
            label: item.name
          };
        });
      setPlatFormList(filteredPlatList);
    } else {
      console.error("editPlatformList is not an array or is undefined.");
    }
  }, [platFormData, editPlatformList]);


  console.log("editPlatformListeditPlatformListeditPlatformList", editPlatformList)


  useEffect(() => {
    const getSelectedOrgPlanData = async () => {
      try {
        const response = await axiosInstance.current.get(
          // `extorg/org/get_org_plan_data/${akou}?ak_id=${login.ak_id}`
          `extorg/admin/orgPlan/${akou}`
        );
        setSelectedPlans(response.data.result);
        setTotalHcp(response.data.totalhcp);
        setTotalPatients(response.data.totalPatient);
        setTotalAdmins(response.data.totalAdmins)
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getSelectedOrgPlanData();
  }, [planDataRefresh]);

  useEffect(() => {
    const getPlansData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/plans?ak_id=${login.ak_id}`
        );
        setPlanData(response.data.data);
      } catch (err) {
        setMessage(err.message);
        setMessageType("error");
        console.log("An error occurred while fetching data.");
      }
    };
    getPlansData();
  }, []);



  useEffect(() => {
    const getEditData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/get_p_org_details/${akou}?ak_id=${login.ak_id}`
        );
        const indOrgData = response.data.data;
        if (response.status === 200) {
          setEditplatformList(indOrgData[0].platforms);
          setOrgName(indOrgData[0].org_name);
          setStreetAdd(indOrgData[0].street_address);
          setCity(indOrgData[0].city_suburb);
          setStateValue(indOrgData[0].state);
          setPostCode(indOrgData[0].postal_code);
          setPhone(indOrgData[0].phone_no);
          setCheckedItems(indOrgData[0].specility[0]);
          setCheckedFeatureItems(indOrgData[0].features[0]);
          setAssignedPlanList(indOrgData[0].plan_id);
          setAddressList(indOrgData[0].address);
          setcountry(indOrgData[0].country_code)
          setAutoJoinPatient(indOrgData[0].patient_check)
          setUploadedImageUrl(indOrgData[0].logo)
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getEditData();
  }, []);

  useEffect(() => {
    const getPlatFormData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getPlatform`
        );
        setPlatFormData(response.data.data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getPlatFormData();
  }, []);

  const handleDel = async (planId) => {
    console.log("plan iD", planId);
    try {
      const result = await axiosInstance.current.delete(
        `extorg/org/delete_plans/${planId}?ak_id=${login.ak_id}`
      );
      if (result) {
        console.log("deleted successfully");
        setMessageType("success");
        setMessage("Plan deleted");
        setPlanDataRefresh((planDataRefresh) => !planDataRefresh);
        setTimeout(() => {
          setMessage(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };

  const addLocations = (e) => {
    e.preventDefault();
    if (!streetAdd) {
      setaddressError(true);
    }
    if (!city) {
      cityRef.current.errUpdate();
    }
    if (!stateValue) {
      stateValueRef.current.errUpdate();
    }
    if (!postCode) {
      postCodeRef.current.errUpdate();
    }
    if (!phone) {
      phoneRef.current.errUpdate();
    }
    if (!streetAdd || !phone || !stateValue || !postCode || !city) return;
    if (editMode !== null) {
      // Update existing address in addressList
      const updatedAddressList = [...addressList];
      updatedAddressList[editMode] = {
        street_address: streetAdd,
        phone_no: phone,
        postal_code: postCode,
        city_suburb: city,
        state: stateValue,
      };
      setAddressList(updatedAddressList);
    } else {
      // Add new address to addressList
      const newAddress = {
        street_address: streetAdd,
        phone_no: phone,
        postal_code: postCode,
        city_suburb: city,
        state: stateValue,
      };
      setAddressList([...addressList, newAddress]);
    }
    // Reset form fields and errors
    setLocationError(false);
    resetLocation();
    setStreetAdd("");
    setCity("");
    setStateValue("");
    setPostCode("");
    setPhone("");
    setEditMode(null); // Reset edit mode
  };
  const handleRemoveItem = (index) => {
    setAddressList(addressList.filter((_, i) => i !== index));
  };
  const handleEditItem = (index) => {
    console.log("index", index);
    setEditMode(index);
    const selecteEditAddress = addressList[index];
    setStreetAdd(selecteEditAddress.street_address);
    setCity(selecteEditAddress.city_suburb);
    setStateValue(selecteEditAddress.state);
    setPostCode(selecteEditAddress.postal_code);
    setPhone(selecteEditAddress.phone_no);
    setIsHidden(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  console.log("addressList--", addressList);

  const handleCountry = (e) => {
    setcountry(e);
  };


  const countryList = async () => {
    try {
      const res = await axiosInstance.current.get(
        "extorg/admin/country_list"
      );
      if (res.status === 200) {
        setCountryLists(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    countryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleAutoJoinPatients = (e) => {
    setAutoJoinPatient(e)
  }


  useEffect(() => {
    setSelectedOption(platFormOptions.filter((option) => editPlatformList.includes(option.value)))
  }, [platFormData, editPlatformList])


  const validateImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.height > 72 || img.width > 248) {
          reject("Please upload a logo with dimensions 72px height and 248px width.");
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        reject("Invalid image file.");
      };
    });
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("profile_pic", file);

    try {
      const response = await axiosInstanceUpload.current.post(
        `extglobal/updateTemplateViewOrganization?ak_id=${login.ak_id}&org_id=${akou}`,
        formData
      );

      if (response && response.data) {
        setUploadedImageUrl(response.data.url);
        setMessage("File uploaded successfully!");
        setMessageType("success");

        setTimeout(() => {
          setMessage(false)
        }, 2000);
      }
    } catch (err) {
      setMessage(err.response?.data?.message || "An error occurred.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }
  };

  const handleRemoveImage = () => {
    setUploadedImageUrl("");
    setMessage("");
    setMessageType("");
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // await validateImageDimensions(file);
        handleFileUpload(file);
      } catch (error) {
        setMessage(error);
        setMessageType("error");
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      try {
        // await validateImageDimensions(file);
        handleFileUpload(file);
      } catch (error) {
        setMessage(error);
        setMessageType("error");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };



  return (
    <div className="edit_org_screen admin_edit__org">
      {message && <Message message={message} type={messageType} />}
      <div className="">
        <div className="mt_30">
          <form autoComplete="off">
            <div className="panel pt_35 pd_25">
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={orgName}
                    ref={orgNameRef}
                    type="text"
                    placeholder="*Organization Name"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeOrgName}
                    styleClass="mxw_100 br_10 mh_50"
                    disabled
                  />
                </div> */}
              </div>
              {/* <div className="text-end">
                <Button
                  onClick={toggleVisibility}
                  value={`${isHidden ? "+ Add Location" : "Hide Location"}`}
                  buttonStyle="btn_fill w_180 mb_20"
                />
              </div> */}
              <div className="">
                {locationError && (
                  <span className="text-danger">
                    Click Add Location to add at least one location
                  </span>
                )}
              </div>
              {!isHidden && (
                <>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 mb_10">
                      <GoogleAddress
                        //  label={'address'}
                        onAddressChange={changeStreetAdd}
                        valid={addressError}
                        value={streetAdd}
                        setPostCode={setPostCode}
                        setCity={setCity}
                        resetLocation={resetLocation}
                        styleClass={"mxw_100 br_10 mh_50"}
                      ></GoogleAddress>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={city}
                        ref={cityRef}
                        type="text"
                        placeholder="*City/Subrub"
                        validators={[
                          {
                            check: Validators.required,
                            message: "This field is required",
                          },
                        ]}
                        onChange={changeCity}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={stateValue}
                        ref={stateValueRef}
                        type="text"
                        placeholder="*State"
                        validators={[
                          {
                            check: Validators.required,
                            message: "This field is required",
                          },
                        ]}
                        onChange={changeStateValue}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={postCode}
                        ref={postCodeRef}
                        type="text"
                        placeholder="*Post Code"
                        validators={[
                          {
                            check: Validators.required,
                            message: "This field is required",
                          },
                          {
                            check: Validators.spacial,
                            message: "Invalid character",
                          },
                          {
                            check: Validators.postcode,
                            message: "Fields only contain 10 character",
                          },
                        ]}
                        onChange={changePostCode}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={phone}
                        ref={phoneRef}
                        type="text"
                        placeholder="*Phone"
                        validators={[
                          {
                            check: Validators.required,
                            message: "This field is required",
                          },
                          {
                            check: Validators.number,
                            message: "This field can only contain numbers.",
                          },
                          {
                            check: Validators.maxmobile,
                            message: "Maximum 10 Numbers are allowed.",
                          },
                        ]}
                        onChange={changePhone}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 text-right">
                      {editMode !== null ? (
                        <Button
                          onClick={addLocations}
                          value="Update"
                          buttonStyle="btn_fill mr_20 w_180"
                        />
                      ) : (
                        <Button
                          onClick={addLocations}
                          value="Save"
                          buttonStyle="btn_fill mr_20 w_180"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="row">

                <div>
                  {addressList.length !== 0 && (
                    <div className="mt-3 mb-3 selected_plans_table view-org">
                      <h4>{orgName}</h4>
                      <hr />
                      <table className="table m-0" style={{ maxWidth: "100%" }}>
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Street</th>
                            <th>Subrub</th>
                            <th>State</th>
                            <th>Postal Code</th>
                            <th>Phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addressList.map((itemval, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{itemval.street_address}</td>
                                <td>{itemval.city_suburb}</td>
                                <td>{itemval.state}</td>
                                <td>{itemval.postal_code}</td>
                                <td>{itemval.phone_no}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="panel pt_35 pd_25">
              <label>Branding</label>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <div className="file_upload">
                    {!uploadedImageUrl ? (
                      <div
                        className={`${fileUploadStyle.drop_zone}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <div>
                          <img src={FileUploadIcon} alt="File Upload Icon" />
                          <h4 className="mt-2 f_600">Upload your brand logo here</h4>
                          {/* <p className={`${fileUploadStyle.small}`}>
                                       or browse files on your computer.
                                     </p> */}
                          <p
                            className={`${fileUploadStyle.small}`}
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            For best results logo <br />dimensions should be <span class="browse-template">248 x 72 px</span>
                          </p>
                          <input
                            type="file"
                            onChange={handleFileChange}
                            name="ufile"
                            id="upload"
                            style={{ display: "none" }}
                            accept=".png,.jpeg,.pdf,.jpg"
                          />
                          <label
                            htmlFor="upload"
                            className={`${fileUploadStyle.browserfilebtn}`}
                          >
                            Browse Files
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${fileUploadStyle.drop_zone}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <div>
                          <img
                            src={s3UrlLink(uploadedImageUrl)}
                            alt="Uploaded"
                            style={{
                              height: "72px",
                              width: "248px",
                              objectFit: "cover",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              marginBottom: "10px",
                            }}

                          />
                          <h4 className="mt-2 f_600">Upload your brand logo here</h4>
                          {/* <p className={`${fileUploadStyle.small}`}>
                                     or browse files on your computer.
                                   </p> */}
                          <p
                            className={`${fileUploadStyle.small}`}
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                          For best results logo <br />dimensions should be <span class="browse-template">248 x 72 px</span>
                          </p>
                          <input
                            type="file"
                            onChange={handleFileChange}
                            name="ufile"
                            id="upload"
                            style={{ display: "none" }}
                            accept=".png,.jpeg,.pdf,.jpg"
                          />
                          <label
                            htmlFor="upload"
                            className={`${fileUploadStyle.browserfilebtn}`}
                          >
                            Browse Files
                          </label>
                        </div>
                        {/* <button className="btn btn-danger" onClick={handleRemoveImage}>
                                     <img src={DeleteIcon} className="delete_pp_icon" alt="icon" /> Remove
                                   </button> */}

                      </div>

                    )}
                    {/* {message && (
                               <p
                                                             className={
                                                                 messageType === "error" ? "text-danger" : "text-success"
                                                             }
                                                         >
                                                             {message}
                                                         </p>
                                                     )} */}
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mb_10 text-end">
                  {/* <UploadTemplate logoUrl={logoUrl} setLogoUrl={setLogoUrl}/> */}
                </div>
              </div>
              <div className="text-end">

              </div>
            </div>


            <div className="panel pt_35 pd_25">
              <div className="row">
                <div className="col-md-6 col-sm-6 pt_10">
                  <label>Allowed platforms *</label>
                  <ol style={{ color: "#3f2783" }}>
                    {platFormList.map((item, index) => {
                      return <li key={index}>{item.label}</li>;
                    })}
                  </ol>
                  <label>Organization Country</label>
                  <img className="c_pointer ms-2 me-2" src={icon_info} data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Once the country is set it cannot be modified later" alt='icon' />
                  <Dropdown
                    data={countrydata}
                    label=""
                    styleClass="language_drp_dwn"
                    value={country}
                    placeholder="Select Country"
                    onChange={(e) => handleCountry(e)}
                    disabled={true}
                  // showCheckbox
                  />

                </div>
                <div className="col-6 edit_plan_listtable">
                  <div>
                    <SelectedPlans
                      selectedPlans={selectedPlans}
                      totalHcp={totalHcp}
                      totalPatients={totalPatients}
                      totalAdmins={totalAdmins}
                      onClickDeletePlan={handleDel}
                    />
                  </div>
                </div>
                {
                  selectedPlatforms.includes(1) && !selectedPlatforms.includes(2) ? (
                    <div className="mt-4">
                      {/* <label>Auto Join Patients To MED Channles ?</label> */}

                      <Checkbox
                        label={"Auto Join Patients To MED Channles ?"}
                        selected={autoJoinPatient}
                        onChange={handleAutoJoinPatients}
                        styleClass="custom_check_box tick f_400 large"
                        id="feature_external_gp"
                        disabled={true}
                      />
                    </div>
                  ) : null
                }


              </div>
            </div>
            <div className="panel pt_35 pd_25">
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_15">
                    <label>{"Accessible features *"} </label>

                    <div className="row">
                      {featureOptions.map((option) => (
                        <div className="col-6">
                          <div
                            className="form-group custom_check_box tick"
                            key={option.id}
                          >
                            <input
                              className="me-2"
                              type="checkbox"
                              value={option.value}
                              checked={checkedFeatureItems[option.value]}
                              disabled={option.locked}
                              onChange={handleChange2}
                              id={"feature" + option.id}
                              name={"feature" + option.id}
                            />
                            <label className="me-4" for={"feature" + option.id}>
                              {" "}
                              {option.label}
                            </label>
                          </div>
                        </div>
                      ))}

                      {featureErrorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </div>
                  </div>
                </div>
                {
                  editPlatformList && editPlatformList.length > 2 || editPlatformList.includes(1) ? (
                    <div className="col-md-6 col-sm-6 mb_10">
                      <div className="mb_15">
                        <label>{"Treatment specialities *"} </label>

                        <div className="row">
                          {options.map((option) => (
                            <div className="col-6">
                              <div
                                className="form-group custom_check_box tick"
                                key={option.id}
                              >
                                <input
                                  className="me-2"
                                  type="checkbox"
                                  value={option.value}
                                  checked={checkedItems[option.value]}
                                  disabled={option.locked}
                                  onChange={handleChange1}
                                  id={"spec" + option.id}
                                  name={"spec" + option.id}
                                />
                                <label className="me-4" for={"spec" + option.id}>
                                  {" "}
                                  {option.label}
                                </label>
                              </div>
                            </div>
                          ))}

                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : ("")
                }

              </div>
            </div>
            <div className="panel pt_35 pd_25">
              <OrgUserList orgName={orgName} platform_id={editPlatformList} editPlatformList={editPlatformList} allPlatData={selectedOption} featureOptions={checkedFeatureItems} />
            </div>

            <div className="panel pt_35 pd_25">
              {/* <h4>List Of Documents</h4> */}
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <MyDocument org_id={akou} />
                </div>

              </div>
            </div>
            <div className="panel pt_35 pd_25">
              {/* <h4>Upload Proof Documents</h4> */}
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <ProofDocument org_id={akou} />
                </div>

              </div>
            </div>
            {/* <div className="panel pt_35 pd_25">
              <h4>Upload Proof Documents</h4>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                <ProofDocument org_id={akou}/>
                </div>
              
              </div>
            </div> */}
          </form>
          <br />
        </div>
      </div>
    </div>
  );
}

export default EditOrganization;
