import React, { useState, useEffect } from "react";
import Button from "../custom/button/Button";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import InputField from "../custom/inputfield/InputField";
import FileUpload from "./FileUpload";
// import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useTranslation from "../customHooks/translations";
import useAxios from "../../axiosinstance";
import DocumentListTable from "./DocumentList";
import SortDropdown from "../custom/sortdropdown/SortDropdown";
// import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import Message from "../custom/toster/Message";
import { useParams } from "react-router-dom";

export default function MyDocument({ org_id }) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [specifyTagsData, setSpecifyTagsData] = useState([])
  // const { akId } = useParams();
  const axiosInstance = useAxios();
  const translation = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  // const [modalNotShow, setNotModalShow]=useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [offset, setOffset] = useState(10);
  const [documentName, setDocumentName] = useState("");
  const [sortType, setSortType] = useState("last_created");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState("");
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const [disble, setDisable] = useState(false)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [editSpecicTags, setEditSpecificTags] = useState([])
  const [isConsent, setIsConsent] = useState(false);

  console.log("disbledisble", disble)
  const { akou } = useParams();
  const handleCloseShow = (e) => {
    setDisable(false)
    // setMessage("Document Uploaded")
    // setMessageType("success");
    setModalShow(false);
  };
  // const handleCloseNotShow=(e)=>{
  //   setModalShow(false)
  // }

  const handleStartDate = (e) => {
    setLastTypingTime(new Date().getTime())
    setStartDate(e);
    setOffset(10);
  };
  const handleEndDate = (e) => {
    setLastTypingTime(new Date().getTime())
    setEndDate(e);
    setOffset(10);
  };

  const handleDocument = (e) => {
    setLastTypingTime(new Date().getTime())
    setDocumentName(e);
  };

  const handleSort = (e) => {
    // setSortType(e);
    setOffset(10);
  };
  console.log("sortTypesortType", sortType)

  const handleReset = (e) => {
    e.preventDefault()
    setDocumentName("");
    setEndDate("");
    setStartDate("");
  };

  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/org/get_admin_documents/${login[0].ak_id}?offset=0&limit=10&doc_name=${documentName}&s_date=${startDate}&e_date=${endDate}&sort_type=${sortType}&type=${37}&akou_id=${akou}`
            );
            setData(response.data.data);
            setTotal(response.data.totalCount);
            const consentExists = response.data.data.some(doc => doc.isConsent);
            setIsConsent(consentExists);
          } catch (error) {

          } finally {
            setIsLoading(false);
          }
        };
        getData();
      }, 1000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }

  }, [modalShow, documentName, startDate, endDate, sortType, dataRefe]);


  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/get_admin_documents/${login[0].ak_id}?offset=0&limit=${limit}&doc_name=${documentName}&s_date=${startDate}&e_date=${endDate}&sort_type=${sortType}&type=${37}&akou_id=${akou}`
        );
        setData(response.data.data);
        setTotal(response.data.totalCount);
      } catch (error) {

      } finally {
        setIsLoading(false);
      }
    };
    getData();

  }, [modalShow, documentName, startDate, endDate, sortType, dataRefe]);

  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extorg/org/get_admin_documents/${login[0].ak_id}?offset=${offset}&limit=${limit}&doc_name=${documentName}&s_date=${startDate}&e_date=${endDate}&sort_type=${sortType}&type=${37}&akou_id=${akou}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(data.concat(resdata));
    }, 500);
  };

  const fetchMoreData = () => {
    addMoreData();
  };


  const specifyTags = async () => {
    try {
      const tagsData = await axiosInstance.current.get(
        `extorg/org/specific_tags?akou_id=${org_id}`
      );
      setSpecifyTagsData(tagsData.data.data)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    specifyTags()
  }, [])

  console.log("specifytagssssssss", specifyTagsData)



  console.log(data, "total");
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="patients__list mb_100">
            <div className="row page_header">
              <div className="col-sm-6 col-md-6">
                <h4 className="mb-3 ml_25">{"List Of Documents"}
                </h4>
                <p className="mb_30 ml_25">
                  {total} {total > 1 ? "Results" : "Result"}
                </p>
              </div>

              <div className="col-sm-6 col-md-6 text-end">
                <span
                  onClick={() => setModalShow(true)}
                  className={`btn_outline c_pointer p-2 px-4`}
                >
                  {/* <img className={`action_icons`} src={downloadIcon} alt="" /> */}
                  <span className="me-2 ">+</span>
                  {"Add Document"}
                </span>
              </div>
            </div>
            <div className="panel pt_30 ">
              <div className="adv_search_sec">
                <h4 className="mb_30">Advanced Search</h4>
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <Form.Group className="mb-3" controlId="fromDate">
                      <Form.Label>From Date</Form.Label>
                      <DatePicker
                        className="form-control mb_0"
                        selected={startDate}
                        maxDate={endDate}
                        placeholderText="mm/dd/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                        dropdownMode="select"
                        onChange={(date) => handleStartDate(date)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <Form.Group className="mb-3" controlId="toDate">
                      <Form.Label>To Date</Form.Label>
                      <DatePicker
                        className="form-control"
                        selected={endDate}
                        minDate={startDate}
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                        dropdownMode="select"
                        placeholderText="mm/dd/yyyy"
                        onChange={(date) => handleEndDate(date)}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-sm-6 col-md-3">
                    <InputField
                      label="Document Name"
                      value={documentName}
                      type="text"
                      placeholder="Document name"
                      onChange={handleDocument}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 m-auto">
                    <Button
                      onClick={handleReset}
                      value="Reset"
                      buttonStyle="btn_fill "
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <h4 className="mb_10 ml_25">{translation.List_Documents}</h4>

                  <div className="col-sm-12 col-md-12 ">
                    <SortDropdown onChange={handleSort} alp={false} setSortType={setSortType} />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 ps-4 pe-4">
                  {data.length > 0 ? (
                    <DocumentListTable
                      docsdata={data}
                      updatePagination={fetchMoreData}
                      specifyTagsData={specifyTagsData}
                      akou={akou}
                      isConsent={isConsent}
                    // total={total}
                    />
                  ) : (
                    <div className="no_data">{translation.No_data_found}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={modalShow}
            onHide={handleCloseShow}
            dialogClassName="modal-lg patient_notes_popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">{"View"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              {/* <h1>New Document</h1> */}
              <FileUpload setDisable={setDisable} disble={disble} />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <div className="col-sm-6 col-md-12 text-end">
                <Button
                  show={modalShow}
                  onClick={handleCloseShow}
                  value="Continue"
                  buttonStyle="btn_fill mr_20 clk_btn_disbled"
                  disabled={disble}
                />
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}
