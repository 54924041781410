import React from "react";
import { useNavigate } from "react-router-dom";
// import SmallProfile from "../small-profile/SmallProfile";
import breadcrumbStyles from "./DocumentInnerBreadcrumb.module.css";
import { useSelector } from "react-redux";

function AddDocumentInnerBreadcrumb({title}) {
  const navigate = useNavigate();
   const login = useSelector((state) => state.mainReducer.loginData.arr[0]);
  // const handleClick = () => {
  //   navigate(-1, { replace: false });s
  // };
  const handleClickHome = () => {
    navigate("/")
  }


  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-md-6 col-sm-6">
           
          
            <ul className={`${breadcrumbStyles.breadcrumb} mt-4`}>
            <li className="c_pointer" onClick={()=>handleClickHome()}>{login.firstname.charAt(0).toUpperCase() +
                login.firstname.slice(1)}{" "}
              {login.lastname.charAt(0).toUpperCase() + login.lastname.slice(1)}</li>
            <li className="c_pointer f_600" >{title}</li>
          </ul>
          
          </div>
       

          {/* <div className="col-md-6 col-sm-6">
            <div className="">
              <SmallProfile/>
            </div>
          </div> */}

        </div>
      </div>
    </>
  );
}
export default AddDocumentInnerBreadcrumb;
