import React,{useState,useEffect} from "react";
import { useTable} from "react-table";
import Switch from "../custom/switch/Switch";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector , useDispatch } from "react-redux";
import { fileSizeBYkb } from "../../utilities/FileSize";
import { documentTime } from "../../utilities/DateFormate";
import PlatformTags from "./PlatformTags";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import { noteRefs } from "../../redux/actions";


function ProofDocList({ docsdata, updatePagination,total,isConsent }) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const AKTOKENORG = localStorage.getItem("AKTOKENORG")
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  // const [doctorID, setDoctorID] = useState(login.ing_ak_id);
  let data = docsdata
  const actionData = {
    view_url: "document-view-details",
   
    delete_url: "",
  };


  const isLoadingComplete = true;
  const columns = React.useMemo(
    () => [
      {
        Header: "Document Title",
        accessor: "document_title",
        Cell: (row) => (
          <span>
          <div  style={{paddingRight:"10px"}}>{row.row.original.document_title}</div>  
          </span>
        ),
      },
      // {
      //   Header: "Type of Document",
      //   accessor: "file_type",
      //   Cell: (row) => (
      //     <span className="text-capitalize">
      //       {DocType(row.row.original.file_extension , row.row.original.file_type)}
      //       {/* {row.row.original.file_type.replace("_", " ")} */}
      //     </span>
      //   ),
      // },
      {
        Header: "Doctor Name",
        accessor: "doctor_name",
        Cell: (row) => <span>{row.row.original.doctor_name}{`(${row.row.original.doctor_id})`} </span>,
      },
      {
        Header: "File type",
        accessor: "file_extension",
        Cell: (row) => <span>{row.row.original.file_extension?.toUpperCase()}</span>,
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span className="c_pointer">
            {/* <button onClick={e=> handleEdit(row.row.original)}>Edit</button> */}
            <TableActionItems
              viewAction="false"
              doc_view1="true"
              editAction="false"
              feedbackAction="false"
              deleteAction="false"
              arrowAction="false"
              other_link="false"
              show_model="true"
              rowName={row.row.original.id}
              rowId={row.row.original.ea_akpt_id}
              rowData={actionData}
              fileName={row.row.original.document_title}
              link_url={row.row.original.file_path}
              // filelinkUrl={row.row.original.file_url}
              filelinkUrl={row.row.original.file_path}
              filetype={row.row.original.file_extension}
              rowType="document"
            />
          </span>
        ),
      },
    ],
    []
  );
  

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <div className="tableContainer" id="rpScrollableDivfffff12">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDivfffff12"
          // loader={<TableSpinner />}
          // loader={ data.length >= 10 && data.length !== data.total  ? <TableSpinner /> : null}
        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                        
                      ])}
                      
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default ProofDocList;
